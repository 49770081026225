label {
    font-weight: 500;
    font-size: 18px;
}

.custom-bg-secondary {
    background-color: #ffffff;
}

.custom-bg-primary {
    background-color: #e4eeff;
}